<template>
  <div>
    <div class="filter-panel">
      <input
        type="text"
        placeholder="搜索文件名"
        style="margin-left: 0"
        v-model="queryFiles.search"
        class="cs-input"
      />

      <CSSelect style="border-radius: 4px; margin-right: 30px">
        <select v-model="queryFiles.state" style="padding-left: 5px">
          <option style="color: #000" value="">文件大小不限</option>
          <option
            v-for="(name, state) in orderStates"
            :key="state"
            :value="name.id"
            style="color: #000"
          >
            {{ name.name }}
          </option>
        </select>
      </CSSelect>

      <button type="button" class="btn btn-primary btn-sm" @click="queryFile()">
        查询
      </button>
    </div>
    <!--主体页面  -->
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel" style="text-align: right">
            <button
              class="btn btn-primary sticky-right"
              @click="openAddFileInfo()"
            >
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              上传资料文件
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th class="text-center">上传时间</th>
            <th class="text-center">文件名称</th>
            <th class="text-center">文件大小</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in fileList" :key="item.id">
            <td class="text-center" style="min-width: 125px">
              {{ item.uploadTime }}
            </td>

            <td class="text-center">
              {{ item.fileName }}
            </td>

            <td class="text-center">
              {{ (+getnum((+item.size / 1024 / 1024)))>=1?+getnum((+item.size / 1024 / 1024)) + 'M':+(getnum((+item.size / 1024)))>=1?getnum((+item.size / 1024)) +"KB":getnum((+item.size ))+'B'}}
            </td>



            <td class="text-center">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>

                <ul class="dropdown-menu">
                  <li>
                    <a
                      style="width: 100%; text-decoration: none"
                      @click="
                        deleteDataFile = true;
                        currentItemId = item.id;
                      "
                    >
                      删除文件
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination />
    </div>

    <CSDialog
      :dialogTitle="`上传资料文件`"
      :dialog-visible="addFileFlag"
      dialog-width="650px"
      dialog-confirm-btn-text="上传"
      @onClose="
        addFileFlag = false;
        fileName = '';
        fromData = '';
      "
      @onConfirm="uploadFiles"
    >
      <template v-slot:dialog-content>
        <div style="padding: 20px 30px">
          <div style="display: relative; margin-bottom: 20px">
            <div class="notice_title" style="width: 96px">选择文件</div>
            <input
              type="text"
              v-model="fileName"
              class="hy-input"
              placeholder="请选择"
              style="width: 350px; margin-right: 20px"
            />
            <button
              type="button"
              @click="upLoadFile"
              class="btn btn-primary"
              style="height: 40px; width: 80px; font-size: 24px; padding: 0"
            >
              浏览
            </button>
          </div>

          <div>
            <svg
              class="icon"
              aria-hidden="true"
              style="font-size: 20px; margin-right: 10px"
            >
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>

            <span style="font-size: 19px;color: #999999"
              >支持上传的文件类型：jpg、png、psd、bmp、txt、doc、wps、
              docx、xls、xlsx、pdf、avi、wmv、rar、zip、MP3、MP4、
              mov、m4v、flv、exe、mav。</span
            >
          </div>
          <input
            type="file"
            class="file"
            id="up_load_file"
            hidden
            @change="chooseFile($event)"
          />
        </div>
      </template>
    </CSDialog>
    <!-- 删除文件弹出框 -->
    <CSDialog
      dialogTitle="提示"
      dialog-header-class=" "
      dialogWidth="480px"
      dialog-confirm-btn-text="确定"
      :dialogVisible="deleteDataFile"
      @onClose="deleteDataFile = false"
      @onConfirm="DeleteDataFile"
      dialog-header-icon="icon-menua-zu13"
    >
      <div
        slot="dialog-content"
        style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        确定删除吗?
      </div>
    </CSDialog>
  </div>
</template>

<script>
import {
  addFile,
  queryFileList,
  removeFile,
  importFileUrl,
  uploadUrl,
  deleteFileListUrl
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination.vue";
import ChooseLocation from "@/components/ChooseLocation";
import DropDownSelection from "@/components/DropDownSelection.vue";
import CSSelect from "@/components/common/CSSelect";

export default {
  name: "datafile",
  components: {
    CSTable,
    Pagination,
    CSDialog,
    ChooseLocation,
    DropDownSelection,
    CSSelect,
  },
  data() {
    return {
      keyword: "",
      fileName: "",

      filterHeight: 0,
      fileList: [],
      addFileFlag: false, //添加文件
      deleteDataFile: false, //删除文件
      file: {
        id: "",
        name: "", //文件名称
        size: "",  //文件大小
      },
      dataFileState: "",
      // 查询状态
      orderStates: [
        { id: 1, name: "1M以内" },
        { id: 2, name: "1~10M" },
        { id: 3, name: "10M~50M" },
        { id: 4, name: "50M~1G" },
        { id: 5, name: "1G以上" },
      ],
      //删除文件id
      currentItemId: "",
      //查询状态
      queryFiles: {
        state: "",
        search: "",
      },
    };
  },
  created() {
    this.queryFile();
    this.$vc.on(this.$route.path, "pagination_page", "event", this.queryFile);
  },
  methods: {
    getnum(num) {
      var aNew;
      num = `${num}`
      var re = /([0-9]+\.[0-9]{2})[0-9]*/;
      aNew = num.replace(re, "$1");
      return aNew
    },
    //查询文件列表
    queryFile(pageNo = 1, pageSize = 10) {
      let minFileSize = "";
      let maxFileSize = "";
      switch (this.queryFiles.state) {
        case 1: {
          minFileSize = "";
          maxFileSize = 1;
          break;
        }
        case 2: {
          minFileSize = 1;
          maxFileSize = 10;
          break;
        }
        case 3: {
          minFileSize = 10;
          maxFileSize = 50;
          break;
        }
        case 4: {
          minFileSize = 50;
          maxFileSize = 1024;
          break;
        }
        case 5: {
          minFileSize = 1024;
          maxFileSize = "";
          break;
        }
      }
      const params = {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        fileName: this.queryFiles.search,
        pageNum: pageNo,
        pageSize,
        minFileSize,
        maxFileSize,
      };
      this.$fly.post(queryFileList, params).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.data.total,
              pageSize,
              currentPage: pageNo,
            });
          }
        }

        this.fileList = res.data.data.list;
        console.log(res.data.list);
      });
    },

    upLoadFile() {
      // trigger触发事件
      $("#up_load_file").trigger("click");
    },

    chooseFile(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        console.log("校检文件"+file.size)
        if (file.size > 50*1024*1024){
         return  this.$vc.toast("文件大小超出限制");
        }
        this.fileName = file.name;

        // var reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = (e) => {
        //   console.log(reader.result);
        //   this.formData = reader.result;
        // }
        var formData = new FormData(); //新建FileReader对象
        formData.append("file", file);
        formData.append("regionCode", this.$vc.getCurrentRegion().code);
        this.formData = formData;
        console.log(this.formData);
      }
    },
    uploadFiles() {
      this.$fly.post(uploadUrl, this.formData).then((res) => {
        if (res.code == -854){
          return;
        }
        if (res.code != 0) {
          this.$vc.toast("上传失败");
          return;
        }
        // this.queryByCondition();
        this.queryFile();
        this.$vc.toast("上传成功");
        this.addFileFlag = false;
        this.formData = null;
      });
    },

    //删除文件
    DeleteDataFile() {
      this.deleteDataFile = true;
      this.$fly
        .delete(`${deleteFileListUrl}/${this.currentItemId}`)
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.deleteDataFile = false;
          this.queryFile();
        });
    },

    //添加文件 - 打开添加遮罩层
    openAddFileInfo() {
      this.addFileFlag = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.notice_title {
  display: inline-block;
  font-size: 24px;
  color: #000;
  text-align: right;
  margin-right: 40px;
  vertical-align: middle;
}
</style>
